import React from 'react'
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  return (
    <div>
      <div className='fixed-top'>

        <div className="ssagroindiacompany">
          <div className="album py-2">

            <div className="container">

              <div className="row ">

                <div className="col-md-5" >
                  <div className="d-flex justify-content-between align-items-center">
                    <a className="" href="tel: 03482 356764"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                    </svg> 03482-356764 </a>

                    <Link to="/bookingroom">
                      <button className="buttontop button2" >ROOM BOOK</button>
                    </Link>



                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">





                </div>
              </div>
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand" href="#"><b>HOTEL SANGAM</b></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page" >Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link active" aria-current="page" >About Us</Link>
                </li>


                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Room
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/super-deluxe-room-berhampore" className="dropdown-item" href="#">Super deluxe Double Bed</Link></li>
                    <li><Link to="/Kingbed" className="dropdown-item" href="#">King size double bed</Link></li>

                    <li><Link to="/Bronzesuite" className="dropdown-item" href="#">Bronze suite </Link></li>
                    <li><Link to="/Platunum_room" className="dropdown-item" href="#">Platinum suite </Link></li>
                    <li><Link to="/Goldsuite" className="dropdown-item" href="#">Gold Suite  </Link></li>
                  </ul>
                </li>




                {/* <li className="nav-item">
                  <Link to="/room" className="nav-link active" aria-current="page" >Room</Link>
                </li> */}

                <li className="nav-item">
                  <Link to="/restaurant" className="nav-link active" aria-current="page" >Restaurant</Link>
                </li>

                <li className="nav-item">
                  <Link to="/Menu" className="nav-link active" aria-current="page" >Menu</Link>
                </li>
                <li className="nav-item">
                  <Link to="/gallery" className="nav-link active" aria-current="page" >Gallery</Link>
                </li>

                <li className="nav-item">
                  <Link to="/event" className="nav-link active" aria-current="page" >Conference & Event</Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Dropdown
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </li> */}
                <li className="nav-item">
                  <Link to="/contactus" className="nav-link " href="#" tabindex="-1" aria-disabled="true">Contact</Link>
                </li>
              </ul>
              <Link to="/bookingroom">
                <button className="buttonnav button2" >ROOM BOOK</button>
              </Link>

            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar