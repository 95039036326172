import React from 'react'
import Restro from "../images/HOME_BANNER.jpg";
import maingate from "../images/Hotel Sangam Restaurant.jpeg";
import Restrophoto from "../images/Hotel Sangam Cafe room.jpeg"
function Sliderrestro() {
  return (
    <div>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>

  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={maingate} className="d-block w-100" alt="hotelsangam_restaurant"/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Hotel Sangam</h5>
        <p>Berhampore, Murshidabad, WB , India </p>
      </div>
    </div>

    <div className="carousel-item">
      <img src={Restro} className="d-block w-100" alt="hotelsangam_bar"/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Hotel Sangam</h5>
        <p>Berhampore, Murshidabad, WB , India</p>
      </div>
    </div>

    <div className="carousel-item">
      <img src={Restrophoto} className="d-block w-100" alt="hotelsangam_bar"/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Hotel Sangam</h5>
        <p>Berhampore, Murshidabad, WB , India</p>
      </div>
    </div>
    
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Sliderrestro